<template>
  <div class="header">
    <headerTitle class="headerTop" />
    <div class="header-content">
      <div class="header-content_btn">
        <div class="top-desc">
          <span>科技新基建</span>
          <span style="margin-top:16px">赋能行业数智化转型</span>
        </div>
        <div class="bottom-desc">
          <span>赋予数据生命，打造精美大屏</span>
        </div>
        <div class="header-content_btn_text">
          <div @click="jumpTo('display')" class="header-content_btn_text_left" style="{'background-image': `-webkit-linear-gradient()`}">在线演示</div>
          <div @click="jumpTo('download')" class="header-content_btn_text_right">源码下载</div>
        </div>
      </div>
        <img class="header-content_bg" src="../../assets/img/大屏设计器1.png" />
    </div>
  </div>
</template>
<script>
import headerTitle from '../Header/bigScreenHeader.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    headerTitle
  },
  methods: {
    open(val) {
      if (val == 'b') {
        window.open('http://gccloud.com/', '_blank')
      } else if (val == 'a') {
        this.$router.push({ path: '/introduce' })
      }
    },
    jumpTo(val) {
      if (val === 'display') {
        window.open('http://gcpaas.gccloud.com/bigScreen', '_blank')
      } else if (val === 'download') {
        window.open('https://gitee.com/gcpaas/DataRoom', '_blank')
      }
    },
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  flex-direction: column;
  background: #0C0D21;
  width: 100%;
  // background: url('~@/assets/img/firstBg.png') no-repeat;
  background-size: 100%;
  position: relative;
  min-height: 550px;

  .headerTop {
    position: fixed;
    top: 0;
    right: 0;
  }

  &-content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 160px 8vw 0 12vw;

    &_btn {
      color: #FFFFFF;
      // font-weight: bolder;
      font-size: 50px;
      line-height: normal;
      letter-spacing: 1px;
      position: relative;
      text-align: left;

      .text {
        white-space: nowrap;
      }

      &_text {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #FFFFFF;
        font-size: 28px;
        line-height: normal;
        margin-top: 40px;

        &_left {
          color: #FFFFFF;
          width: 190px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          cursor: pointer;
          border-radius: 105px;
          // border: 2px solid #007AFF;
          background-image: -webkit-linear-gradient(0deg,#2868ff 0%, #39c1ff 100%);

          &:hover {
            box-shadow: 0 5px 8px rgba(0, 122, 255, .5);
          }
        }

        &_right {
          color: #FFFFFF;
          width: 190px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          cursor: pointer;
          border-radius: 105px;
          background-image: -webkit-linear-gradient(0deg,#7135ff 0%, #38c4ff 100%);

          &:hover {
            box-shadow: 0 5px 8px rgba(0, 122, 255, .5);
          }
        }
      }

    }

    &_bg {
      // transform:scale();
      max-width: 550px;
      position: absolute;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }
  }
}

/deep/.el-dropdown {
  color: #ffffffd2;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    color: #FFFFFF;
  }
}

/deep/ .el-dropdown-menu {
  top: 50px !important
}

/deep/ .el-popper {
  top: 50px !important
}

.top-desc{
  font-size: 52px;
  span{
    display: block;
  }
}
.bottom-desc{
  font-size: 28px;
  margin-top: 16px;
  font-weight: 300;
  padding-top: 14px;
  border-top: 1px solid #999999;
  span{
    width: 100%;
    display: block;
    text-align: justify;
  text-align-last: justify;
  }
}
</style>
