<template>
  <div class="home">
    <Header/>
    <first-content/>
    <second-content/>
    <img-list/>
    <Footer/>
    <Tootip/>
  </div>
</template>

<script>
import Header from '../../components/BigScreen/Header.vue'
import firstContent from '../../components/BigScreen/content1.vue'
import secondContent from '../../components/BigScreen/content2.vue'
import imgList from '../../components/BigScreen/ImgList.vue'
import Footer from '../../components/Footer/Footer.vue'
import Tootip from '../../components/BigScreen/tootip.vue'
export default {
  components:{Header,firstContent,secondContent,imgList,Footer,Tootip},
  data() {
    return {
    }
  },

  methods: {
    //登录
  },
}
</script>

<style lang="less" scoped>
.home {
  background: #0C0D21;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  min-width: 1300px;
  
}
</style>
