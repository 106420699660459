<template>
  <div class="content">
    <img  class="content_bg" src="../../assets/img/大屏设计器2.png" alt="">
    <div class="content-list">
      <div class="content-list_title">图形化设计</div>
      <div class="content-list_item">
        <div class="content-list_item_title"><div class="dot"></div>可视化拖拽</div>
        <div class="content-list_item_content">强大的拖拽设计能力，帮助用户快速实现精准的界面构建</div>
      </div>
     <div class="content-list_item">
        <div class="content-list_item_title"><div class="dot"></div>丰富图组件</div>
        <div class="content-list_item_content">丰富的图表能力，展示数据直观清晰，助力决策分析</div>
      </div>
      <div class="content-list_item">
        <div class="content-list_item_title"><div class="dot"></div>自定义图表</div>
        <div class="content-list_item_content">支持Echarts、G2Plot图表自定义注册、配置、使用</div>
      </div>
      <div class="content-list_item">
        <div class="content-list_item_title"><div class="dot"></div>所见即所得</div>
        <div class="content-list_item_content">实时设计、实时预览、无需等待、容易上手</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
   width: 100%;
   min-height:700px;
   background: url('~@/assets/img/bolangBg.png') no-repeat;
   margin-top: -50px;
   position: relative;
  //  z-index: 99;
   flex-direction: row;
   padding: 100px 100px 0 100px;
   background-size: cover;
   align-items: center;
   justify-content: space-around;
    &_bg{
      // transform:scale();
      max-width: 600px;
      overflow: hidden;
    }
   &-list {
    margin-left: 54px;
    &_title{ 
      color: #FFFFFF;
      font-weight: bolder;
      font-size: 32px;
      line-height: normal;
      margin-bottom: 40px;
    }
    &_item{ 
      &_content{
          color: #ffffffd2;
          font-size: 20px;
          line-height: 36px;
          letter-spacing: 0px;
          margin:0 34px 16px 0;
        }
      &_title{
       color: #FFFFFF;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 12px;
        .dot {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #FFFFFF;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
          margin-right:22px;
        }
      }
    }
   }
}
</style>
