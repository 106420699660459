import { render, staticRenderFns } from "./bigScreen.vue?vue&type=template&id=0f1d96fa&scoped=true"
import script from "./bigScreen.vue?vue&type=script&lang=js"
export * from "./bigScreen.vue?vue&type=script&lang=js"
import style0 from "./bigScreen.vue?vue&type=style&index=0&id=0f1d96fa&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f1d96fa",
  null
  
)

export default component.exports