<template>
  <div class="content">
    <div class="content-header">丰富的使用场景</div>
    <div class="content-text">拥有若干现成案例，即拿即用，免费商用</div>
    <div class="content-list">
      <img src="../../assets/img/imgList1.png" alt="">
      <img src="../../assets/img/imgList2.png" alt="">
      <img src="../../assets/img/imgList3.png" alt="">
      <img src="../../assets/img/imgList4.png" alt="">
      <img src="../../assets/img/imgList5.png" alt="">
      <img src="../../assets/img/imgList6.png" alt="">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
.content{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 80px 180px 0 180px;
  background: #070C19;
  &-header{
    color: #FFFFFF;
    font-weight: bolder;
    font-size: 32px;
    line-height: normal;
    letter-spacing: 0px;
    margin-bottom: 16px;
  }
  &-text{
    color: #ffffffd2;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: 0px;
    margin-bottom: 56px;
  }
  &-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    img{
      margin-bottom:16px;
      width: 32%;
    }
  }
}
</style>
