<template>
  <div class="content">
    <div class="content-list">
      <div class="content-list_title">无缝集成</div>
      <div class="content-list_item">
        <div class="content-list_item_title"><div class="dot"></div>多数据接入</div>
        <div class="content-list_item_content">提供丰富的数据接入和处理方式，提高了数据可视化的灵活性和效率</div>
      </div>
     <div class="content-list_item">
        <div class="content-list_item_title"><div class="dot"></div>多系统集成</div>
        <div class="content-list_item_content">设计器以依赖包的形式被使用，支持组件化集成</div>
      </div>
      <div class="content-list_item">
        <div class="content-list_item_title"><div class="dot"></div>权限自定义</div>
        <div class="content-list_item_content">支持对接项目安全认证，保证大屏数据安全</div>
      </div>
    </div>
     <img class="content_bg" src="../../assets/img/大屏设计器3.png" alt="">
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style lang="less" scoped>
.content {
  display: flex;
   width: 100%;
   min-height:550px;
   position: relative;
   padding: 30px 100px 0 100px;
  //  z-index: 99;
   flex-direction: row;
   background-size: cover;
   align-items: center;
   justify-content: space-around;
   &_bg{
      // transform:scale();
      max-width: 600px;
      overflow: hidden;
    }
   &-list {
    margin-left: 54px;
    &_title{ 
      color: #FFFFFF;
      font-weight: bolder;
      font-size: 32px;
      line-height: normal;
      margin-bottom: 40px;
    }
    &_item{ 
      &_content{
          color: #ffffffd2;
          font-size: 20px;
          line-height: 36px;
          letter-spacing: 0px;
          margin:0 34px 16px 0;
        }
      &_title{
       color: #FFFFFF;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0px;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 12px;
        .dot {
          width: 12px;
          height: 12px;
          border-radius: 100%;
          background: #FFFFFF;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .2);
          margin-right:22px;
        }
      }
    }
   }
}
</style>
